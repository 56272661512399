import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { GlobalConfig } from '../models/global-config';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class GlobalConfigService {
    _isLoggedIn$ = new BehaviorSubject<boolean>(
        this.authenticationService.isLoggedIn()
    );
    private readonly databaseUrl: string;

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService
    ) {
        this.databaseUrl = environment.backendUrl;
    }

    getConfig(): Observable<GlobalConfig> {
        const url = `${this.databaseUrl}/rest/globalConfig`;
        return this.http
            .get<GlobalConfig>(url)
            .pipe(catchError(this.handleError));
    }

    updateConfig(globalConfig: GlobalConfig): Observable<GlobalConfig> {
        //   console.log("Global Config in updateConfig:" + globalConfig.trainingInCloud)
        const url = `${this.databaseUrl}/rest/globalConfig`;
        return this.http
            .put<GlobalConfig>(url, globalConfig)
            .pipe(catchError(this.handleError));
    }

    get isLoggedIn() {
        return this._isLoggedIn$.asObservable();
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`
            );
        }
        return throwError(
            'Error in response from Back-End. Contact technical Staff'
        );
    }
}
