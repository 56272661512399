import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ImgDb } from './model/img-db';
import { ImgDbService } from './services/img-db.service';
import { ImgDbInfo } from './model/img-db-info';
import { ImgCategory } from './model/img-category';
import { ImgWithSelection } from './model/img-with-selection';
import { Observable, Subscription, tap } from 'rxjs';
import { ImgAnnotation } from './model/img-annotation';
import { ImgDbZoomService } from './services/img-db-zoom.service';
import { CategoryWithSelection } from './model/category-with-selection';
import { AnnotationStateService } from './services/annotation-state.service';
import { ImgLabelingViewerComponent } from './img-labeling-viewer/img-labeling-viewer.component';
import { TrainingService } from '../shared/services/training.service';
import { TrainingDTO } from '../shared/models/training-dto';
import { TrainingStatus } from '../shared/models/training-status';
import { map, take } from 'rxjs/operators';
import { ImgDbUploadService } from './services/img-db-upload.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ImagesStorageService } from './services/images-storage.service';
import { PagingResult } from '../shared/models/pageable';
import { Container } from '../shared/services/container/container.types';

export enum SelectionButtonMode {
    Select = 'Select',
    Deselect = 'Deselect',
}

@Component({
    selector: 'app-img-db',
    templateUrl: './img-db.component.html',
    styleUrls: ['./img-db.component.scss'],
    providers: [ImgDbUploadService, ImagesStorageService],
})
export class ImgDbComponent implements OnInit, OnDestroy {
    readonly MANAGE_DIALOG_PAGE_SIZE: number = 100;
    readonly LABELING_DIALOG_PAGE_SIZE: number = 8;

    navItems: string[] = [
        'imgDb.navItem.Manage',
        'imgDb.navItem.Label',
        //'imgDb.navItem.Select',
    ];
    activeViewIdx: number;
    imageDb: ImgDb;
    containerId: string;
    dbInfo: ImgDbInfo;
    categories: ImgCategory[] = [];
    selectedCategoryNames: string[] = [];
    selectedEditCategory: ImgCategory;
    isEmptyCategorySelected: boolean = false;
    uncategorizedImagesCount: number;
    annotations: ImgAnnotation[];

    dbName: string = '';
    trainingName: string;

    routingData = {
        name: '',
        path: '/workspace',
    };

    categoryTree = {};

    currentImagesInRow: number;
    lengthOfImagesList: number = 0;

    selectButtonMode: SelectionButtonMode = SelectionButtonMode.Select;
    selectButtonEnabled: boolean = true;

    isPanButtonActive: boolean = false;

    @ViewChild(ImgLabelingViewerComponent)
    labelComponent: ImgLabelingViewerComponent;

    training: TrainingDTO;
    isDbFromTraining: boolean = false;
    canEdit: boolean = true;
    imgDbId: string = '';
    currentPage: number = 1;

    subscriptions: Subscription[] = [];

    public constructor(
        private service: ImgDbService,
        private route: ActivatedRoute,
        private zoomService: ImgDbZoomService,
        private location: Location,
        private changeDetector: ChangeDetectorRef,
        public annotationStateService: AnnotationStateService,
        private trainingService: TrainingService,
        private uploadService: ImgDbUploadService,
        public imagesStorage: ImagesStorageService
    ) {}

    ngOnInit(): void {
        this.populateDataAfterRetrieveImageDb();
        this.activeViewIdx = 0;
        this.annotationStateService.$annotations.next([]);
        this.setZoomLevel();

        this.uploadService.uploadProcessState.subscribe((data) => {
            if (data) {
                // TODO: Remove subscription. Upload logic is already doing all the stuff
                this.populateDataAfterRetrieveImageDb();
            }
        });

        this.imagesStorage.selectedImage$.subscribe(
            (image: ImgWithSelection) => {
                this.onImageSelectedForEditing(image);
            }
        );
    }

    private populateDataAfterRetrieveImageDb() {
        this.retrieveImageDb()
            .pipe(
                tap((imageDb: ImgDb) =>
                    this.imagesStorage.setData(
                        this.MANAGE_DIALOG_PAGE_SIZE,
                        imageDb.id
                    )
                ),
                tap((db: ImgDb) => this.setImageDb(db)),
                tap((db: ImgDb) => this.getDbInfo(db)),
                tap((db: ImgDb) => this.getCategories(db)),
                tap((_) => this.getImages())
            )
            .subscribe((imgDb) => {
                this.imgDbId = imgDb.id;
            });
    }

    retrieveImageDb(): Observable<ImgDb> {
        if (this.isTrainingProvidedInUrl()) {
            this.getTraining();
            return this.service.getImageDbByTrainingId(
                this.extractTrainingIdFromUrl()
            );
        } else {
            return this.service.getImageDb(this.extractDbIdFromUrl()).pipe(
                map((container: Container) => {
                    this.containerId = container.id;
                    const imgDb = container.imageDb;
                    imgDb.name = container.name;
                    return imgDb;
                })
            );
        }
    }

    getTraining() {
        this.trainingService
            .get(this.extractTrainingIdFromUrl())
            .pipe(take(1))
            .subscribe((training: TrainingDTO) => {
                this.training = training;
                this.isDbFromTraining = true;
                this.canEdit = training.status === TrainingStatus.NEW;
            });
    }

    setZoomLevel() {
        this.zoomService.setDefaultValue();

        this.subscriptions.push(
            this.zoomService.imagesInRowManageDialog.subscribe((count) => {
                this.currentImagesInRow = count;
            })
        );

        this.subscriptions.push(
            this.zoomService.zoomLevelLabelDialog.subscribe((level) => {
                this.onZoomLevelChanged(level);
            })
        );
    }

    getImages() {
        this.imagesStorage
            .getImages()
            .subscribe((pageable: PagingResult<ImgWithSelection>) => {
                this.changeSelectButtonMode(SelectionButtonMode.Select);
                this.lengthOfImagesList = pageable.values.length;
                this.selectButtonEnabled = this.lengthOfImagesList > 0;
            });
    }

    isTrainingProvidedInUrl(): boolean {
        return !!this.extractTrainingIdFromUrl();
    }

    extractDbIdFromUrl(): string {
        return this.route.snapshot.params['imgDbId'];
    }

    extractTrainingIdFromUrl() {
        return this.route.snapshot.params['trainingId'];
    }

    getBackButtonName() {
        let res = '';

        if (this.training) {
            res = this.training.name;
        } else if (this.imageDb) {
            res = this.imageDb.name;
        }

        return res;
    }

    onNavClick(idx: number): void {
        if (idx !== this.activeViewIdx) {
            this.activeViewIdx = idx;
            this.resetCurrentPage(false);
        }
        this.zoomService.setCurrentZoomLevel(0);
        this.changeSelectButtonMode(SelectionButtonMode.Select);
    }

    onNameUpdate(val: string): void {
        this.service.updateName(this.containerId, val).subscribe((imgDb) => {
            this.setImageDb(imgDb);
        });
    }

    onSelectEditCategory(category: ImgCategory): void {
        // selection of Category in Label/Annotation Editor
        this.isPanButtonActive = false;
        if (this.selectedEditCategory === category) {
            this.toggleImageDraggingInChildComponent(this.isPanButtonActive);
        }
        this.selectedEditCategory = category;
    }

    onImageSelectedForEditing(img: ImgWithSelection) {
        if (img !== null) {
            this.changeDetector.detectChanges();
            this.annotationStateService.fetchAnnotations(
                this.imageDb.id,
                img.id
            );
        }
    }

    handleCategorySelect(category: CategoryWithSelection) {
        category.active = !category.active;
        this.updateImgAnnotations(category);
    }

    handleCategoryDelete(id: Number) {
        const deletedAnnotationIndex = this.annotations.findIndex(
            (annotation: ImgAnnotation) =>
                annotation.id.toString() === id.toString()
        );

        if (deletedAnnotationIndex >= 0) {
            this.annotations.splice(deletedAnnotationIndex, 1);
        }
    }

    updateImgAnnotations(category: CategoryWithSelection) {
        const selectedAnnotation = this.annotations.find(
            (annotation: ImgAnnotation) =>
                annotation.id.toString() === category.annotationId.toString()
        );
        if (selectedAnnotation) selectedAnnotation.active = category.active;
    }

    updateCategoryTree(annotation: ImgAnnotation) {
        const category = this.categories.find(
            (cat) => cat.id + '' === annotation.categoryId + ''
        );
        if (annotation.active) this.categoryTree[category.name].active = true;
        this.categoryTree[category.name].items.forEach((item) => {
            if (item.annotationId.toString() === annotation.id.toString())
                item.active = annotation.active;
        });
    }

    onAddAnnotation(annot: ImgAnnotation) {
        this.annotationStateService.addAnnotation(annot);
    }

    onZoomLevelChanged(level: number) {
        if (level === 0) {
            this.isPanButtonActive = false;

            if (this.labelComponent) {
                this.toggleImageDraggingInChildComponent(
                    this.isPanButtonActive
                );
            }
        }
        this.changeDetector.detectChanges();
    }

    private setImageDb(imgDb: ImgDb): void {
        this.imageDb = imgDb;
        this.routingData.name = imgDb.name;
    }

    back(): void {
        this.location.back();
    }

    isViewActive(idx: number): boolean {
        return idx === this.activeViewIdx;
    }

    isNameEditable(): boolean {
        return this.activeViewIdx === 0;
    }

    getA11yForName(): string {
        return 'Name of current Image Database';
    }

    onSelectAllButtonClick(isAllSelected: boolean) {
        this.service.selection.next(isAllSelected);
        if (isAllSelected) {
            this.changeSelectButtonMode(SelectionButtonMode.Deselect);
        } else {
            this.changeSelectButtonMode(SelectionButtonMode.Select);
        }
    }

    changeSelectButtonMode(mode: SelectionButtonMode) {
        this.selectButtonMode = mode;
    }

    getDbInfo(db: ImgDb) {
        this.service
            .getImageDbInfo(db.id)
            .subscribe((info) => (this.dbInfo = info));
    }

    public createAndPersistCategory(categoryName: string) {
        let category: ImgCategory = new ImgCategory(undefined, categoryName, 0);
        this.categories.push(category);
        this.dbInfo.categoryCount++;
        this.service
            .createCategory(categoryName, this.imageDb.id)
            .subscribe((createdCategory) => {
                category.id = createdCategory.id;
            });
    }

    public selectOrUnselectCategory(clickedCategoryName: string) {
        this.modifySelectedCategoryNames(clickedCategoryName);
        this.resetCurrentPage(true);
    }

    private modifySelectedCategoryNames(clickedCategoryName: string) {
        if (!this.selectedCategoryNames.includes(clickedCategoryName)) {
            this.selectedCategoryNames.push(clickedCategoryName);
        } else {
            this.removeClickedCategoryAsSelected(clickedCategoryName);
        }
        this.imagesStorage.selectedCategoryNames = this.selectedCategoryNames;
    }

    private removeClickedCategoryAsSelected(clickedCategory: string) {
        this.selectedCategoryNames = this.selectedCategoryNames.filter(
            (category) => category !== clickedCategory
        );
    }

    public deleteCategoryAndPersist(categoryIdToDelete: string) {
        let cat: ImgCategory;
        this.categories = this.categories.filter((category) => {
            if (category.id === categoryIdToDelete) {
                cat = category;
                return false;
            }
            return true;
        });

        if (this.selectedCategoryNames.includes(cat.name)) {
            this.selectOrUnselectCategory(cat.name);
        }

        this.dbInfo.categoryCount--;
        this.service.deleteCategory(categoryIdToDelete, this.imageDb.id);
    }

    private getCategories(db: ImgDb) {
        this.service.getCategories(db.id).subscribe((response) => {
            this.categories = response.categories;
            this.uncategorizedImagesCount = response.uncategorizedImagesCount;
        });
    }

    public handleEmptyCategorySelected() {
        this.isEmptyCategorySelected = !this.isEmptyCategorySelected;
        this.imagesStorage.isEmptyCategorySelected =
            this.isEmptyCategorySelected;
        this.resetCurrentPage(true);
    }

    isSelectedImageBookmarked(): boolean {
        return !!(
            this.imagesStorage.selectedImage$.value &&
            this.imagesStorage.selectedImage$.value.isBookmarked
        );
    }

    toggleBookmark() {
        this.imagesStorage.toggleBookmark();
    }

    undo() {
        this.annotationStateService.undo();
    }

    redo() {
        this.annotationStateService.redo();
    }

    /**
     * Method receives one of three string values (see below) from html file and calls service
     * @param zoomAction has one of three values: 'in', 'out', 'default'
     * 'in' -> zoom in, each time one image in a row less
     * 'out' -> zoom out, each time one image in a row more
     * 'default' -> set to default view which is 6 images in a row
     */
    handleZoom(zoomAction: string) {
        if (this.lengthOfImagesList !== 0) {
            this.zoomService.handleZoom(zoomAction, this.activeViewIdx);
        }
    }

    get currentZoomLevel() {
        return this.zoomService.getCurrentZoomLevel(this.activeViewIdx);
    }

    get currentMaxZoom() {
        return this.zoomService.getCurrentMax(this.activeViewIdx);
    }

    get currentMinZoom() {
        return this.zoomService.getCurrentMin(this.activeViewIdx);
    }

    get currentDefaultZoom() {
        return this.zoomService.getCurrentDefault(this.activeViewIdx);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    onPanButtonClick() {
        this.isPanButtonActive = !this.isPanButtonActive;
        this.toggleImageDraggingInChildComponent(this.isPanButtonActive);
    }

    private toggleImageDraggingInChildComponent(enable: boolean) {
        this.labelComponent.toggleImageDragging(enable);
    }

    onItemDropped(event: NgxFileDropEntry[]) {
        this.imagesStorage.selectedCategoryNames = this.selectedCategoryNames =
            [];

        this.imagesStorage.isEmptyCategorySelected =
            this.isEmptyCategorySelected = false;

        //TODO: resetCurrentPage true when subscribe on uploadProcessState removed?
        this.resetCurrentPage(false);
        this.uploadService.processImageFilesUpload(this.imgDbId, event);
    }

    getValidImageFormats(): string[] {
        return this.uploadService.VALID_FILE_FORMATS;
    }

    /**
     *  Will split the key by '.' and take the last element and add '-button' to the result.
     * @param navItemTranslationKey is a translation key for each nav element (imgDb.navItem.Manage)
     */
    getUniqueNavItemId(navItemTranslationKey: string) {
        const parts = navItemTranslationKey.split('.');
        return parts[parts.length - 1] + '-button';
    }

    resetCurrentPage(retrieveAllImages: boolean) {
        this.imagesStorage.currentPage = this.currentPage = 1;
        this.resetPageSize();
        if (retrieveAllImages) {
            this.imagesStorage.getImages().pipe(take(1)).subscribe();
        }
    }

    private resetPageSize() {
        if (this.activeViewIdx === 0) {
            this.imagesStorage.pageSize = this.MANAGE_DIALOG_PAGE_SIZE;
        }
        if (this.activeViewIdx === 1) {
            this.imagesStorage.pageSize = this.LABELING_DIALOG_PAGE_SIZE;
        }
    }

    private createImageDbFromContainer(container: Container): ImgDb {
        return new ImgDb(container.id, container.name, container.creator);
    }
}
