import { Component, Input, OnInit } from '@angular/core';
import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { MessageService } from '../../../message/services/message.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { UserProject } from '../../models/user-project';
import { ProjectService } from '../../services/project.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-user-projects',
    templateUrl: './user-projects.component.html',
    styleUrls: ['./user-projects.component.scss'],
})
export class UserProjectsComponent implements OnInit {
    @Input()
    userId: number;

    projects: UserProject[] = [];

    constructor(
        private projectService: ProjectService,
        private dialog: MatDialog,
        private messageService: MessageService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.fetchProjects();
    }

    leaveProject(userProject: UserProject): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            confirmButtonText: this.translate.instant(
                'project.components.userProjects.yes'
            ),
            cancelButtonText: this.translate.instant(
                'project.components.userProjects.no'
            ),
            title: this.translate.instant(
                'project.components.userProjects.leaveProject'
            ),
            message: this.translate.instant(
                'project.components.userProjects.leaveProjectConfirmation',
                { name: userProject.project.name }
            ),
        };

        this.dialog
            .open(ConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.projectService
                        .leaveProject(this.userId, userProject.project.uuid)
                        .subscribe(
                            () => {
                                this.messageService.displaySuccessMessage(
                                    this.translate.instant(
                                        'project.components.userProjects.projectWasLeft',
                                        { name: userProject.project.name }
                                    )
                                );
                                this.fetchProjects();
                            },
                            (error) => {
                                this.messageService.displayHttpErrorOrDefault(
                                    error,
                                    this.translate.instant(
                                        'project.components.userProjects.leaveProjectError'
                                    )
                                );
                            }
                        );
                }
            });
    }

    deleteJoinRequest(userProject: UserProject): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            confirmButtonText: this.translate.instant(
                'project.components.userProjects.yes'
            ),
            cancelButtonText: this.translate.instant(
                'project.components.userProjects.no'
            ),
            title: this.translate.instant(
                'project.components.userProjects.deleteRequest'
            ),
            message: this.translate.instant(
                'project.components.userProjects.takeBackRequestConfirmation',
                { name: userProject.project.name }
            ),
        };

        this.dialog
            .open(ConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.projectService
                        .deleteJoinRequest(
                            this.userId,
                            userProject.project.uuid
                        )
                        .subscribe(
                            () => {
                                this.messageService.displaySuccessMessage(
                                    this.translate.instant(
                                        'project.components.userProjects.takeBackRequestDone',
                                        { name: userProject.project.name }
                                    )
                                );
                                this.fetchProjects();
                            },
                            (error) => {
                                this.messageService.displayHttpErrorOrDefault(
                                    error,
                                    this.translate.instant(
                                        'project.components.userProjects.takeBackError'
                                    )
                                );
                            }
                        );
                }
            });
    }

    private fetchProjects(): void {
        this.projectService.getUserProjects(this.userId).subscribe((result) => {
            this.projects = result;
        });
    }
}
