import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MessageData, MessageType } from '../message/models/message';
import { MessageService } from '../message/services/message.service';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { ImageObject } from './image-object';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../authentication/services/user.service';

@Component({
    selector: 'app-image-update',
    templateUrl: './image-update.component.html',
    styleUrls: ['./image-update.component.scss'],
})
export class ImageUpdateComponent implements OnChanges {
    @Input()
    originalPicture: ArrayBuffer | string | null;
    @Input()
    originalImageObjects: ImageObject[] = [];
    @Input()
    uploadLimit = 1;
    @Input()
    editable: boolean;

    @Output()
    uploadFiles: EventEmitter<File[]> = new EventEmitter<File[]>();
    @Output()
    uploadFile: EventEmitter<File> = new EventEmitter<File>();

    dialogRef: MatDialogRef<ImageCropperComponent>;

    fileUploadNames: string[];

    currentPicture: ArrayBuffer | string | null;
    currentImageObjects: ImageObject[];

    newFiles: File[] = [];

    constructor(
        private messageService: MessageService,
        private modalService: NgbModal,
        private translate: TranslateService,
        private userService: UserService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.originalPicture &&
            changes.originalPicture.currentValue !==
                changes.originalPicture.previousValue
        ) {
            this.currentPicture = this.originalPicture;
        }
        if (
            changes.originalImageObjects &&
            changes.originalImageObjects.currentValue !==
                changes.originalImageObjects.previousValue
        ) {
            this.resetCurrentImageObjects();
        }
    }

    onClickSliderPicture(index: number): void {
        this.currentPicture = this.currentImageObjects[index].image;
    }

    cancel(): void {
        this.currentPicture = this.originalPicture;
        this.resetCurrentImageObjects();
    }

    openImageCropper(files: File[]): void {
        const modalRef = this.modalService.open(ImageCropperComponent, {
            windowClass: 'image-cropper-modal-class',
        });
        modalRef.componentInstance.imageFile = files[0];
        modalRef.result.then((result) => {
            if (result) {
                var sub = this.userService
                    .readFileOrBlob(result)
                    .subscribe((value) => {
                        this.currentPicture = value;
                        this.uploadFile.emit(result);
                        sub?.unsubscribe();
                    });
            }
        });
    }

    upload(files: File[]): void {
        this.fileUploadNames = [];
        if (files.length === 0) {
            return;
        }

        if (files.length > this.uploadLimit) {
            if (this.uploadLimit === 1) {
                this.messageService.displayErrorMessage(
                    this.translate.instant(
                        'imageUpdate.imageUpdate.imageError1'
                    )
                );
            } else {
                this.messageService.displayErrorMessage(
                    this.translate.instant(
                        'imageUpdate.imageUpdate.imageError2',
                        { imageLimit: this.uploadLimit }
                    )
                );
            }
            return;
        }

        for (const file of files) {
            if (file.type.match(/image\/*/) === null) {
                this.messageService.displayErrorMessage(
                    this.translate.instant(
                        'imageUpdate.imageUpdate.imageError3'
                    )
                );
                return;
            }
            const fileSize = file.size;
            const fileSizeInKB = Math.round(fileSize / 1024);
            if (fileSizeInKB > 20480) {
                this.messageService.displayErrorMessage(
                    this.translate.instant(
                        'imageUpdate.imageUpdate.imageError4'
                    )
                );
                return;
            }
        }

        this.newFiles = files;
        if (this.uploadLimit === 1) {
            this.openImageCropper(files);
            return;
        } else {
            for (let i = 0; i < files.length; i++) {
                var sub = this.userService
                    .readFileOrBlob(files[i])
                    .subscribe((value) => {
                        if (i === 0) {
                            this.currentPicture = value;
                        }
                        this.currentImageObjects.push({
                            image: value,
                            thumbImage: value,
                        });
                        sub?.unsubscribe();
                    });
            }
            this.save();
        }
    }

    save(): void {
        if (this.newFiles.length === 0) {
            this.messageService.displayErrorMessage(
                this.translate.instant('imageUpdate.imageUpdate.imageError5')
            );
            return;
        }
        if (this.uploadLimit === 1) {
            this.uploadFile.emit(this.newFiles[0]);
        } else {
            this.uploadFiles.emit(this.newFiles);
        }
    }

    addFileUploadName(filename: string): void {
        this.fileUploadNames.push(filename);
    }

    showSuccess(): void {
        const messageData: MessageData = {
            type: MessageType.SUCCESS,
            message: {
                text: this.translate.instant(
                    'imageUpdate.imageUpdate.imageSuccess'
                ),
                list: this.fileUploadNames,
            },
        };
        this.messageService.displayMessage(messageData);
    }

    showError(error: string): void {
        this.messageService.displayErrorMessage(error);
    }

    private resetCurrentImageObjects(): void {
        this.currentImageObjects = [];
        for (const image of this.originalImageObjects) {
            this.currentImageObjects.push(image);
        }
    }
}
