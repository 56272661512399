import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Project } from 'src/app/project/models/project';
import { Container } from 'src/app/shared/services/container/container.types';
import { DeleteService } from 'src/app/shared/services/delete.service';

const DEFAULT_PROFILE_IMAGE = 'assets/img/card-icons/default-profile-image.jpg';

const DEFAULT_ITEM_IMAGE = 'assets/img/card-icons/default-item.png';

export abstract class Card {
    readonly cardTypeTranslationKey: string;
    navigationUrl: string;
    navigationUrlParams?: { [key: string]: any };
    deleteCardService: DeleteService;
    router: Router;
    options?: CardOptions;

    public constructor(
        public itemId: string,
        public userId: string,
        public userName: string,
        public footer: CardFooter,
        public pictures?: string[],
        public rootObj?: Container
    ) {
        this.itemId = itemId;
        this.userId = userId;
        this.userName = userName;
        this.footer = footer;
        this.rootObj = rootObj;

        if (!!pictures && pictures.length > 0) {
            this.pictures = pictures;
        } else {
            this.pictures = [DEFAULT_ITEM_IMAGE];
        }
    }

    public abstract deleteCard(): Observable<unknown>;

    public abstract onCardPictureClick(): void;
}

export class CardFooter {
    constructor(public title: string, public userProfilePicture: string) {
        userProfilePicture
            ? (this.userProfilePicture = userProfilePicture)
            : (this.userProfilePicture = DEFAULT_PROFILE_IMAGE);
    }
}

export class CardOptions {
    constructor(
        public disableDeleteButton: boolean = false,
        public isOnClickDisabled: boolean = false,
        public showPrivacyStatus: boolean = true
    ) {}
}
